import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Row className="post-video-row" mdxType="Row">
      <Column colMd={5} colLg={12} className="bx--type-expressive-heading-03" mdxType="Column">
        <h2>{`Progressive Web`}</h2>
        <p>{`Progressive Web applications are responsive on any device and enhance progressively. Discoverable in search engines. We use modern technology including JAM-stack, Angular, React, Vue.`}</p>
        <h2>{`Marketing Support`}</h2>
        <p>{`One of our main goals in increasing capacity is the web applications we create for our customers. Wizbord’s offer is globally expanded to meet its own needs and requirements through our custom web applications with a wide range of satisfied customers. Speak to our solution experts to learn how we can help your ideas grow.`}</p>
        <p>{`Our intuitive technology architecture enables businesses to achieve total independence and operational efficiency. We strive for efficiency in our application development by applying approved design standards and checking them. It helps us to produce stable and from day one valuable web applications for our clients. Management of flexible activities means that services are delivered to our customers on time and on budget.`}</p>
        <div style={{
          backgroundColor: '#343444',
          padding: '2rem'
        }}>
          <img {...{
            "src": "/images/marketing.svg",
            "alt": "Marketing"
          }}></img>
        </div>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      